<template>
	<div id="setores">
		<div class="tabela-setor">
			<div class="col-12 novo-setor">
				<v-btn class="primary-button" raised @click="dialog = true" :disabled="$store.getters.company_id == null ? true : false">
					<i class="mr-2 fas fa-plus"></i> Cadastrar Setor
				</v-btn>
			</div>
			<CustomTable 
				v-if="headers != ''"
				:action="'getSetores'"
				:getter="$store.getters.setores"
				:headers="headers"
				:search="true"
				:title="name"
				:icone="'fa fa-id-badge'"
				:pagination="true"
				:filters="filtros"
				ref="tabela"
			>
				<template v-slot:acoes="{ item }">
					<v-btn class="primary-button" raised small @click="editar(item.id)" :disabled="$store.getters.company_id == null ? true : false">
						Editar
					</v-btn>
				</template>
			</CustomTable>
			<v-dialog v-model="dialog" persistent max-width="600px">
				<v-card>
					<v-card-title>
						<span v-if="dados._id" class="headline">Editar Setor</span>
						<span v-else class="headline">Novo Setor</span>
					</v-card-title>
					<v-card-text>
						<v-container>
							<v-form ref="form_department">
								<div class="row">
									<div class="col-12">
										<v-text-field 
											:rules="[v => !!v || 'Campo Nome obrigatório']" 
											v-model="dados.nome" 
											label="Nome" 
											placeholder="Nome" 
											outlined
										/>
									</div>
								</div>
							</v-form>
						</v-container>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="darken-1" text style="text-transform: capitalize; font-size: 16px;" @click="close">
							Cancelar
						</v-btn>
						<v-btn class="primary-button" large @click="enviar">
							Salvar
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
			<DialogMensagem :visible="dialog_resposta" :mensagem="resposta" @close="dialog_resposta=false"/>
			<Loader v-if="loading"/>
		</div>
	</div>
</template>

<script>
	// importa o store
	import store from '@/store'
	// importa o componente de DialogMensagem 
	import DialogMensagem from '@/components/DialogMensagem.vue'
	// importa o componente de Loader 
	import Loader from '@/components/Loader.vue'
	// importa o componente de CustomTable 
	import CustomTable from '@/components/CustomTable.vue'

	// exporta o componente
	export default {
		// nome do componente
		// componentes filhos
		components: {
			// componente de DialogMensagem
			DialogMensagem,
			// componente de CustomTable
			CustomTable,
			// componente de Loader
			Loader,
		},
		// dados do componente
		data: () => ({
			// nome do componente
			name: 'Setores',
			name_adicionar: 'Setor',
			empresas: [],
			// variável para mostrar a modal para editar/criar um menu
			dialog: false,
			// variável para mostrar a modal de informação
			dialog_resposta: false,
			// variável para a mensagem de resposta
			resposta: {},
			// variável para o loading
			loading: false,
			// variável para criar/editar menu
			dados: {
				_id: '',
				nome: '',
			},
			// variável para o cabeçalho da tabela
			headers: [
				{
					value: 'id',
					text: 'Id do Setor',
					sortable: true,
				},
				{
					value: 'name',
					text: 'Nome',
					sortable: true,
				},
				{
					value: 'company.name',
					text: 'Empresa',
					sortable: true,
				},
				{
					value: 'acoes',
					sortable: false,
					text: 'Ações',
				},
			],
			// variável para os filtros da tabela
			filtros: {
				perPage: 20,
			},

			setores:[]
		}),
		// funções deste componente
		methods: {
			// função para pegar as empresas
			async getSetores(){
				// faz a requisição para o back para coletar as telefones
				const resp = await store.dispatch('getSetoresSelect')
				// caso o status da resposta seja 200 (deu certo)
				if(resp.status == 200){
					// atribui a resposta na variavel telefones
					this.setores = resp.data.departments
				}
			},
			// função para enviar um menu
			async enviar(){
				let resp = {}
				// caso os campos do formulário estejam válidos
				if (this.$refs.form_department.validate()){
					// coloca o componente como loading
					this.loading = await true
					// coloeta os dados do menu
					let dados = await {
						// coleta o nome do menu
						name: await this.dados.nome,
					}
					if(this.dados._id){
						// coleta o status do setor
						// dados.status = await this.usuario.status == 'Ativo' ? true : false

						let date_update = await {
							dados: dados,
							id: this.dados._id
						}

						resp = await store.dispatch('putDepartment', date_update)
					}else{
						// faz a requisição para o back para coletar os menus
						resp = await store.dispatch('postSetor', dados)
					}
					// caso o status da resposta seja 200 (deu certo)
					if(resp.status != 200 && resp.status != 201){
						// atribui o título da mensagem 
						this.resposta.titulo = await 'Algo deu errado!'
						// atribui o corpo da mensagem 
						this.resposta.mensagem = await  resp.data.message || resp.data.error
						// mostra a mensagem
						this.dialog_resposta = true
					// caso tenha dado algum erro
					}else{
						// atribui o título da mensagem 
						this.resposta.titulo = await this.name_adicionar + ' '  +  (this.dados._id ? 'editado!' : 'cadastrado!')
						// atribui o corpo da mensagem 
						this.resposta.mensagem = await this.name_adicionar + ' ' + (this.dados._id ? 'editado' : 'cadastrado') + ' com sucesso!'
						// mostra a mensagem
						this.close()
						// fecha a modal de create/edit menu
						this.dialog_resposta = true
					}
					// retira o loading do componente
					this.loading = false
					// atualiza a tabela
					this.$refs.tabela.init()
				}
			},
			// função para coletar um setor para editar
			async editar(_id){
				// coloca o componente como loading
				this.loading = true
				// faz um dispatch para uma action no vuex store para pegar um setor passando o id 
				var resp = await store.dispatch('getSetor', _id)
				// caso o status da resposta seja 200 (deu certo) 
				if(resp.status == 200){
					// atribui os dados vindos do back à váriável local
					this.dados._id = await resp.data.id || ''
					this.dados.nome = await resp.data.name || ''
					// mostra a modal de creat/edit dados
					this.dialog = true
				}else{
					// atribui o título da mensagem 
					this.resposta.titulo = await 'Algo deu errado!'
					// atribui o corpo da mensagem 
					this.resposta.mensagem = await  resp.data.message || resp.data.error
					// mostra a mensagem
					this.dialog_resposta = true
				}
				// retira o loading do componente
				this.loading = false
				// atualiza a tabela
				this.$refs.tabela.init()
			},
			// função para pegar as empresas
			async getEmpresas(){
				// faz a requisição para o back para coletar as empresas
				var resp = await store.dispatch('getEmpresaSelect')
				// caso o status da resposta seja 200 (deu certo)
				if(resp.status == 200){
					// atribui a resposta na variavel empresas
					this.empresas = resp.data
				}
			},
			// função que roda quando é fechada a modal de create/edit menu
			close(){
				// fecha a modal
				this.dialog = false
				// limpa os dados locais do menu
				this.dados =  {
					_id: '',
					nome: '',
				}
			},
		},
		// funções que rodam quando o componente é montado
		mounted(){
			// função de início do componente
			// this.init()
		},
	}
</script>

<style lang="scss" scoped>
	#setores{
		display: flex;
		max-height: 100%;
		padding: 24px;
		flex-wrap: wrap;
		.tabela-setor{
            width: 100%;
			background-color: #fff;
    		border-radius: 10px;
			.novo-setor{
				display: flex;
				justify-content: flex-end;
				padding-bottom: 0;
				button{
					i, svg{
						margin-right: 10px;
					}
					text-transform: capitalize;
				}
			}
		}
	}
</style>